import React from "react"
import BackgroundImage from "gatsby-background-image"
import { getImage, StaticImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { graphql, Link } from "gatsby"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Layout from "../components/layout"
import Seo from "../components/seo"

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "top.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, placeholder: NONE)
      }
    }
  }
`

const Index = ({ data, location }) => {
  return (
    <Layout location={location}>
      <Seo />
      <BackgroundImage
        Tag="div"
        {...convertToBgImage(getImage(data.heroImage))}
      >
        <div className="hero-image">
          <div className="card hero-message">
            <div className="card-content">
              <div className="content">
                <div className="concept" style={{ textAlign: "center" }}>
                  The Customer <span style={{ color: "yellow" }}>First</span>
                  <div style={{ fontSize: "0.6em" }}>
                    お客様と繋がるご縁を大切に
                  </div>
                </div>
                <div style={{ paddingTop: "15px", textAlign: "right" }}>
                  株式会社ファースト
                </div>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="section">
        <div className="index-message">
          <p>
            株式会社ファーストは「お客様と繋がるご縁を大切に」を第一に考え、お客様のご要望を叶える為、ベストプランをご提案致します。
          </p>
          <p>
            その場限りではなく、その先も身近な存在となるトータル不動産を目指しております。
          </p>
        </div>

        <div className="columns is-multiline is-mobile">
          <div className="column is-half-mobile ">
            <Link to="/buy" className="link-image">
              <StaticImage
                src="../images/icons-big/buy.jpg"
                placeholder="none"
                quality={90}
                alt=""
              />
              <div className="link-image-text">
                <p>
                  購入{"　"}
                  <FontAwesomeIcon icon={faChevronRight} />
                </p>
              </div>
            </Link>
          </div>
          <div className="column is-half-mobile">
            <Link to="/sell" className="link-image">
              <StaticImage
                src="../images/icons-big/sell.jpg"
                placeholder="none"
                quality={50}
                alt=""
              />
              <div className="link-image-text">
                <p>
                  売却{"　"}
                  <FontAwesomeIcon icon={faChevronRight} />
                </p>
              </div>
            </Link>
          </div>
          <div className="column is-half-mobile">
            <Link to="/rent" className="link-image">
              <StaticImage
                src="../images/icons-big/rent.jpg"
                placeholder="none"
                quality={90}
                alt=""
              />
              <div className="link-image-text">
                <p>
                  借り入れ {"　"}
                  <FontAwesomeIcon icon={faChevronRight} />
                </p>
              </div>
            </Link>
          </div>
          <div className="column is-half-mobile">
            <Link to="/lend" className="link-image">
              <StaticImage
                src="../images/icons-big/lend.jpg"
                placeholder="none"
                quality={90}
                alt=""
              />
              <div className="link-image-text">
                <p>
                  貸し出し{"　"}
                  <FontAwesomeIcon icon={faChevronRight} />
                </p>
              </div>
            </Link>
          </div>
        </div>

        <div className="columns is-multiline is-mobile">
          <div className="column is-half-mobile ">
            <Link to="/invest" className="link-image">
              <StaticImage
                src="../images/icons-big/invest.jpg"
                placeholder="none"
                quality={90}
                alt=""
              />
              <div className="link-image-text">
                <p>
                  投資{"　"}
                  <FontAwesomeIcon icon={faChevronRight} />
                </p>
              </div>
            </Link>
          </div>
          <div className="column is-half-mobile">
            <Link to="/inherit" className="link-image">
              <StaticImage
                src="../images/icons-big/inherit.jpg"
                placeholder="none"
                quality={90}
                alt=""
              />
              <div className="link-image-text">
                <p>
                  相続{"　"}
                  <FontAwesomeIcon icon={faChevronRight} />
                </p>
              </div>
            </Link>
          </div>
          <div className="column is-half-mobile">
            <Link to="/remodel" className="link-image">
              <StaticImage
                src="../images/icons-big/remodel.jpg"
                placeholder="none"
                quality={90}
                alt=""
              />
              <div className="link-image-text">
                <p>
                  リフォーム{"　"}
                  <FontAwesomeIcon icon={faChevronRight} />
                </p>
              </div>
            </Link>
          </div>
          <div className="column is-half-mobile">
            <Link to="/company" className="link-image">
              <StaticImage
                src="../images/icons-big/company.jpg"
                placeholder="none"
                quality={90}
                alt=""
              />
              <div className="link-image-text">
                <p>
                  企業情報{"　"}
                  <FontAwesomeIcon icon={faChevronRight} />
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Index
